import { getEntryPoint } from 'hybridspace-telemetry';
import { DatapointStatus, logGreyError, PerformanceDatapoint } from 'owa-analytics';
import { useManagedQuery } from 'owa-apollo-hooks';
import { scrubForPii } from 'owa-config';
import ErrorCircleRegular from 'owa-fluent-icons-svg/lib/icons/ErrorCircleRegular';
import { getQueryStringParameter } from 'owa-querystring';
import { trace } from 'owa-trace';
import React from 'react';
import { action } from 'satcheljs';
import strings from './useManagedQueryWithError.locstring.json';
import { useShowNotificationOnError } from './useShowNotificationOnError';

import type {
    QueryResult,
    QueryHookOptions,
    TypedDocumentNode,
    OperationVariables,
    ApolloError,
} from '@apollo/client';
import type { StrictVariables } from 'owa-apollo';
import type { OperationDefinitionNode } from 'graphql';
interface CustomOptions {
    shouldLog?: (error: ApolloError) => boolean;
}

export function useManagedQueryWithError<
    TData,
    TSchemaVars extends OperationVariables,
    TActualVars extends StrictVariables<TSchemaVars, TActualVars>
>(
    query: TypedDocumentNode<TData, TSchemaVars>,
    managedOptions: QueryHookOptions<TData, TActualVars> = {},
    customOptions: CustomOptions = {}
): QueryResult<TData, TActualVars> {
    const skip = managedOptions?.skip;
    const operationName = (query.definitions[0] as OperationDefinitionNode)?.name?.value;

    const perfDatapoint = React.useMemo(() => {
        if (!skip) {
            const datapoint = new PerformanceDatapoint('Places_useManagedQueryWithError');
            datapoint.addCustomData({
                entryPoint: getEntryPoint(),
                operationName,
            });
            return datapoint;
        } else {
            return null;
        }
    }, [operationName, skip]);

    /**
     * onErrorLink logs graphQLErrors only, expand the onError handling to also report and log for networkError,
     * to cover the scenarios when cafe, between client and Gateway microservice, is not working as happened 3/6.
     */
    const newManagedOptions: QueryHookOptions<TData, TActualVars> = {
        ...managedOptions,
        context: {
            ...managedOptions.context,
            gatewayGraphRequest:
                managedOptions.context?.gatewayGraphRequest ??
                getQueryStringParameter('devGraphqlMainthread'),
        },
        onError: (error: ApolloError) => showOnError(error, customOptions, operationName),
    };

    const schemaResult: QueryResult<TData, any> = useManagedQuery(query, newManagedOptions);

    const graphqlError = schemaResult?.error?.graphQLErrors?.[0];
    const hasGraphQLError = !!graphqlError;
    const networkError = schemaResult?.error?.networkError;
    const errorState = hasGraphQLError
        ? `OperationName: ${operationName}, Message: ${graphqlError?.message}, InnerMessage: ${
              graphqlError?.extensions?.InnerMessage ?? graphqlError?.message
          }`
        : networkError
        ? `OperationName: ${operationName}, Message: ${networkError?.message}, Name: ${networkError?.name}`
        : '';

    const loading = schemaResult.loading;
    const error = schemaResult.error;
    React.useEffect(() => {
        if (perfDatapoint && !perfDatapoint.hasEnded && !loading) {
            if (error) {
                perfDatapoint.endWithError(
                    networkError ||
                        (error.graphQLErrors?.[0]?.extensions?.code?.indexOf('SERVER') ?? 0) > -1
                        ? DatapointStatus.ServerError
                        : DatapointStatus.ClientError
                );
            } else {
                perfDatapoint.end();
            }
        }
    }, [perfDatapoint, loading, error, networkError]);

    useShowNotificationOnError(
        'HybridspaceNotificationBarHost',
        undefined,
        strings.useManagedQueryFailed,
        ErrorCircleRegular,
        strings.useManagedQuerySubmitFeedback,
        () => {
            onSendErrorFeedback(errorState);
        }
    );

    return schemaResult as QueryResult<TData, TActualVars>;
}

function showOnError(
    error: ApolloError,
    customOptions: CustomOptions,
    operationName: string | undefined
) {
    if (customOptions?.shouldLog && !customOptions.shouldLog(error)) {
        return;
    } else {
        if (error.networkError) {
            trace.warn(
                `[Places networkError error]: Name: ${error.networkError.name} Message: ${error.networkError.message}`
            );
            logGreyError('Places_useManagedQueryWithError_networkError', error.networkError, {
                name: error.networkError.name /** Can be Error | ServerParseError | ServerError */,
            });
            throw error.networkError;
        }
        if (error.graphQLErrors) {
            const graphQLErrors = error?.graphQLErrors?.[0];
            const errorState = `Message: ${
                graphQLErrors?.message
            }, operationName: ${operationName}, InnerMessage: ${
                graphQLErrors?.extensions?.InnerMessage ?? graphQLErrors?.message
            }`;
            trace.warn(`[Places GraphQL error]: ${errorState}`);
            logGreyError('Places_useManagedQueryWithError_graphQLErrors', graphQLErrors, {
                name: graphQLErrors?.name,
                path: graphQLErrors.path?.toString(),
                operationName,
                code: graphQLErrors?.extensions?.code,
                innerMessage: scrubForPii(
                    graphQLErrors?.extensions?.InnerMessage ?? graphQLErrors?.message
                ),
                additionalCodes: graphQLErrors?.extensions?.codes?.toString(),
                locations: graphQLErrors?.extensions?.locations?.toString(),
            });
        }
    }
}

export const onSendErrorFeedback = action('onSendErrorFeedback', (errorState: string) => ({
    errorState,
}));
