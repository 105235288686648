import { userDate } from 'owa-datetime';
import { setOccurringFWH } from 'places-user-session-stores';
import { getWorkLocationForDay } from './getWorkLocationForDay';

import type { OwaDate } from 'owa-datetime';
import type { FlexibleWorkingHoursView, WorkLocationType, Location } from 'owa-graph-schema';
/**
 * This function is going to take the FWH for the user's occurring work plan
 * and set the key as the DayOfWeek and the value as the work location for that day, taking all segments for that day into account
 * @param flexibleWorkingHours
 */

export default function setOccurringFWHInStore(
    flexibleWorkingHours: FlexibleWorkingHoursView[][] | undefined | null
) {
    const occurringFWH = new Array<{
        date: OwaDate;
        workLocation: WorkLocationType;
        workLocationDetails?: Location[];
    }>();

    if (flexibleWorkingHours) {
        flexibleWorkingHours?.forEach((fwhData: FlexibleWorkingHoursView[]) => {
            if (fwhData.length > 0) {
                const date = userDate(fwhData[0].start.dateTime);
                const workLocation = getWorkLocationForDay(fwhData);
                const workLocationDetails: Location[] = [];
                fwhData.every((fwhDaySegment: FlexibleWorkingHoursView) => {
                    if (fwhDaySegment.workLocationDetails) {
                        workLocationDetails.push(fwhDaySegment.workLocationDetails);
                    }
                });
                occurringFWH.push({ date, workLocation, workLocationDetails });
            }
        });
        const sortedOccurringFWH = [...occurringFWH].sort(
            (elementA, elementB) => Number(elementA.date) - Number(elementB.date)
        );

        setOccurringFWH(sortedOccurringFWH);
    }
}
