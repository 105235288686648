import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type MailboxCalendarConfiguration from 'owa-service/lib/contract/MailboxCalendarConfiguration';
import { getCalendarConfigurationState } from './getCalendarConfigurationState';

export function getCalendarConfiguration(
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): MailboxCalendarConfiguration | null {
    return getCalendarConfigurationState(mailboxInfo).calendarConfiguration;
}
