import type {
    EnhancedLocation,
    LocationIdType,
    LocationEntityType,
    LocationSourceType,
} from 'owa-graph-schema';
import { logUsage } from 'owa-analytics';

export default function getWorkLocationDetails(
    isOfficeLocation: boolean,
    personalLocation: EnhancedLocation
) {
    const isNotDefaultOption = personalLocation?.Id;

    let annotation = '';
    if (personalLocation?.DisplayName?.toLowerCase()?.includes('neighborhood')) {
        annotation = 'client modified a neighborhood - getWorkLocationDetails';
        logUsage('PlacesUpdatedNeighborhoodAsBuilding');
    }

    return isOfficeLocation && isNotDefaultOption
        ? {
              displayName: personalLocation?.DisplayName || 'undefined',
              annotation, // this place can be use to add the information of hiding location setting
              postalAddress: {
                  street: '',
                  city: '',
                  state: '',
                  country: '',
                  postalCode: '',
                  postOfficeBox: '',
              },
              id: personalLocation?.Id || '',
              idType: 'Directory' as LocationIdType,
              locationUri: '',
              locationType: 'Building' as LocationEntityType,
              locationSource: 'Resource' as LocationSourceType,
              // Coordinates are always set to 0 to keep compliance working
              coordinates: {
                  longitude: 0,
                  latitude: 0,
                  accuracy: 0,
                  altitude: 0,
                  altitudeAccuracy: 0,
              },
          }
        : null;
}
