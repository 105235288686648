import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getCalendarConfigurationState } from '../selectors/getCalendarConfigurationState';
import type TxpGriffinSettingsPayload from '../store/schema/TxpGriffinSettingsPayload';

export default mutatorAction(
    'updateTxpCalendarConfiguration',
    function updateTxpCalendarConfiguration(
        settings: TxpGriffinSettingsPayload,
        mailboxInfo: MailboxInfo
    ) {
        const config = getCalendarConfigurationState(mailboxInfo);
        if (config.txpSettings) {
            Object.assign(config.txpSettings, settings);
        } else {
            config.txpSettings = settings;
        }
    }
);
