import type { CollaboratorGroup } from 'owa-graph-schema';

export const savedCollaboratorsProp: CollaboratorGroup[] = ['Saved'];
export const teamCollaboratorsProp: CollaboratorGroup[] = ['Team'];
export const suggestedCollaboratorsProp: CollaboratorGroup[] = ['Suggested'];
export const savedAndSuggestedCollaboratorProp: CollaboratorGroup[] = ['Saved', 'Suggested'];
export const savedAndTeamCollaboratorProp: CollaboratorGroup[] = ['Saved', 'Team'];
export const OID_NOT_RESOLVED = 'OID can not resolved for collaborator.';
export const MAX_COLLABORATORS_LIMIT = 40;
export const MAX_CALENDAR_VIEW_LIMIT = 50;
