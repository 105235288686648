import useListUserFeatureAccess from 'hybridspace-graphql/lib/hooks/useListUserFeatureAccess';
import { ADMIN_ROOT, SPACE_ANALYTICS, SPACE_MANAGEMENT } from '../constants/adminRoutes';
import { isSpaceAnalyticAdminTabEnabled } from './adminUtils';

export function useDefaultAdminScreenId() {
    const { featureAccess } = useListUserFeatureAccess();
    const isSpaceAnalyticAdminEnabled = isSpaceAnalyticAdminTabEnabled(featureAccess);

    const defaultAdminTab = isSpaceAnalyticAdminEnabled ? SPACE_ANALYTICS : SPACE_MANAGEMENT;

    return `/${ADMIN_ROOT}/${defaultAdminTab}`;
}
