import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { UserEmailAddress } from './UserEmailAddress';

/**
 * Returns the email address of the current user.
 * This does not take multi-account settings into account.
 *
 * This function assumes user configuration settings to have been loaded already.
 * This is the case for normal application code, which runs after bootstrap.
 */
export function getUserEmailAddress() {
    return getModuleContextMailboxInfo().mailboxSmtpAddress as UserEmailAddress;
}
