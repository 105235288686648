import { lazyShowNotification } from 'owa-notification-bar';
import { NotificationBarCallbackReason } from 'owa-notification-bar/lib/callbacksMap/NotificationBarCallbackReason';
import type NotificationBarHostId from 'owa-notification-bar/lib/store/schema/NotificationBarHostId';
import loc from 'owa-localize';
import type { ResourceId } from 'owa-localize';

/**
 * Show a notification bar with a message and optional action button.
 *
 * @param hostId ID of the NotificationBar that will show the notification.
 * Expected usage is for applications to create a bound function with the hostId
 * and then call that function to show the notification, so we don't have to duplicate
 * the hostId in every call.
 *
 * @param messageTextResourceId Resource ID of the message text. We expect the messages to be
 * short, well-known strings instead of dynamic strings. If needed, actionCallback can be used
 * to open a dialog with additional details.
 *
 * @param messageIconName Name of an icon to show next to the message.
 * @param actionTextResourceId Resource Id of the action button text.
 * @param actionCallback Callback called when the action button is clicked.
 */
export function showNotification(
    hostId: NotificationBarHostId,
    messageTextResourceId: ResourceId,
    messageIconName?: string,
    actionTextResourceId?: ResourceId,
    actionCallback?: () => void
) {
    lazyShowNotification.importAndExecute(
        (Date.now() + Math.random()).toString(),
        hostId,
        window,
        loc(messageTextResourceId),
        {
            icon: messageIconName,
            primaryActionText: actionTextResourceId ? loc(actionTextResourceId) : undefined,
            notificationCallback: (reason: NotificationBarCallbackReason) => {
                if (reason === NotificationBarCallbackReason.PrimaryActionClicked) {
                    actionCallback?.();
                }
            },
        }
    );
}
