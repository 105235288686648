import type MailboxCalendarConfiguration from 'owa-service/lib/contract/MailboxCalendarConfiguration';
import getMailboxCalendarConfigurationOperation from 'owa-service/lib/operation/getMailboxCalendarConfigurationOperation';
import type GetMailboxCalendarConfigurationResponse from 'owa-service/lib/contract/GetMailboxCalendarConfigurationResponse';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';

export default async function getMailboxCalendarConfiguration(
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): Promise<MailboxCalendarConfiguration> {
    const response: GetMailboxCalendarConfigurationResponse =
        await getMailboxCalendarConfigurationOperation(getMailboxRequestOptions(mailboxInfo));
    if (response.ErrorCode) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (11,9): Type 'null' is not assignable to type 'MailboxCalendarConfiguration'.
        // @ts-expect-error
        return null;
    } else {
        return response.Options;
    }
}
