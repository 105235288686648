import { UpdateFlexibleWorkingHoursDocument } from 'owa-calendar-working-hours-option/lib/graphql/__generated__/updateFlexibleWorkingHoursMutation.interface';
import getWorkLocationDetails from './getWorkLocationDetails';
import type { MailboxInfo } from 'owa-client-types';
import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import type {
    FlexibleWorkingHoursInput,
    EnhancedLocation,
    ResolverContext,
} from 'owa-graph-schema';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function updateRecurringFlexibleWorkHours(
    mailboxInfo: MailboxInfo,
    oldSegment: FlexibleWorkingHoursInput
) {
    const isOffice: boolean = oldSegment?.workLocationType === 'Office';
    //// Add the dropdown selection for the building to save the value
    const workLocationDetails = getWorkLocationDetails(isOffice, {
        Id: oldSegment?.workLocationDetails?.id,
        DisplayName: oldSegment?.workLocationDetails?.displayName,
    } as EnhancedLocation);

    const isMultiAccountEnabled = isFeatureEnabled('cal-surface-places-multi-account');

    const context = isMultiAccountEnabled
        ? ({
              requestOptions: {
                  mailboxInfo,
              },
          } as ResolverContext)
        : undefined;

    return {
        mutation: UpdateFlexibleWorkingHoursDocument,
        variables: {
            input: {
                mailboxId: isMultiAccountEnabled
                    ? mailboxInfo.mailboxSmtpAddress
                    : getUserEmailAddress(),
                updateDetails: {
                    segment: {
                        id: oldSegment.id,
                        start: {
                            dateTime: oldSegment.start.dateTime,
                            timeZone: {
                                name: oldSegment.start.timeZone.name,
                            },
                        },
                        end: {
                            dateTime: oldSegment.end.dateTime,
                            timeZone: {
                                name: oldSegment.start.timeZone.name,
                            },
                        },
                        workLocationType: oldSegment.workLocationType,
                        recurrenceDetailsWH: {
                            recurrence: {
                                recurrencePattern: {
                                    weeklyRecurrencePattern: {
                                        firstDayOfWeek:
                                            oldSegment.recurrenceDetailsWH?.recurrence
                                                .recurrencePattern?.weeklyRecurrencePattern
                                                .firstDayOfWeek || 0,
                                        daysOfWeek:
                                            oldSegment.recurrenceDetailsWH?.recurrence
                                                .recurrencePattern?.weeklyRecurrencePattern
                                                .daysOfWeek,
                                        interval: 1,
                                    },
                                },
                            },
                            timeZone: {
                                name: oldSegment.recurrenceDetailsWH?.timeZone.name || 'UTC',
                            },
                        },
                        workLocationDetails,
                    },
                },
            },
        },
        context,
    };
}
