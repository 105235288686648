import { tokens } from '@fluentui/react-theme';
/**
 * This is the places finder's viewStateId for singleton view state in the places app. This ID is deprecated since places finder now supports multiple viewStates.
 * If you are creating a new usage of the places finder, do not use this constant, but create your viewStateId.
 */
export const placesFinderSingletonViewStateId = 'Places';
export const placesFinderDeskReservationViewStateId = 'PlacesFinderDeskReservation';
export const colorPaletteCornflowerForeground = '#2C3C85';
export const defaultColorScheme = {
    calDarkAlt: tokens.colorBrandForeground2,
    calDark: tokens.colorBrandForegroundOnLightPressed,
    calPrimary: tokens.colorBrandForeground1,
    calLight: tokens.colorBrandStroke2,
    calLighter: tokens.colorBrandBackground2Hover,
    calLighterAlt: tokens.colorBrandBackground2,
    calTertiary: tokens.colorBrandStroke2Hover,
    calSecondary: tokens.colorBrandBackgroundSelected,
    calDarker: tokens.colorBrandForeground2Pressed,

    accessibleText: {
        calTertiaryBackground: tokens.colorBrandForeground2,
        calLightBackground: tokens.colorBrandForeground2,
        calLighterBackground: tokens.colorBrandForeground2,
        calPrimaryBackground: tokens.colorNeutralForegroundOnBrand,
    },
};
