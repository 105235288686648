import { isAnonymousUser } from 'owa-config/lib/anonymousUtils';

export function isBookWithMeModule(): boolean {
    const currentAppPath = window.location.href.toLocaleLowerCase();
    return currentAppPath.includes('bookwithme');
}

export function isAnonymousEventifyModule(): boolean {
    const currentAppPath = window.location.pathname.toLocaleLowerCase();
    return /^\/eventify\//.test(currentAppPath) && isAnonymousUser();
}
