import { getUserConfiguration } from 'owa-session-store';

/**
 * Returns the session settings for the current user.
 *
 * This function assumes user configuration settings to have been loaded already.
 * This is the case for normal application code, which runs after bootstrap.
 */
export function getUserSessionSettings() {
    return getUserConfiguration()?.SessionSettings || {};
}
