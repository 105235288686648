import { getUserSessionSettings } from './getUserSessionSettings';

import type { ExternalDirectoryTenantGuid } from './ExternalDirectoryTenantGuid';

/**
 * Returns the ExternalDirectoryTenantGuid (OID) of the current user.
 *
 * This function assumes user configuration settings to have been loaded already.
 * This is the case for normal application code, which runs after bootstrap.
 */
export function getUserExternalDirectoryTenantGuid() {
    return (getUserSessionSettings().ExternalDirectoryTenantGuid ||
        '') as ExternalDirectoryTenantGuid;
}
