import React from 'react';
import { setModuleStateSafe } from './selectors';
import { getModuleState } from './Store';

// TODO: handle failure condition
export function useModuleStateComplete(successCondition: boolean) {
    // Keeping it as a hook for now. Will iterate on this going forward
    React.useEffect(() => {
        if (successCondition && getModuleState() !== 'complete') {
            setModuleStateSafe('complete');
        }
    }, [successCondition]);
}
