import type {
    WorkLocationType,
    FlexibleWorkingHours,
    FlexibleWorkingHoursView,
    GetScheduleFlexibleWorkingHoursView,
} from 'owa-graph-schema';

// given the flexible working hours segments for a day, returns if the primary location is
// "Office", "Remote", "Unspecified"

export function getWorkLocationForDay(
    segments:
        | FlexibleWorkingHours[]
        | FlexibleWorkingHoursView[]
        | GetScheduleFlexibleWorkingHoursView[]
): WorkLocationType {
    let hasRemote = false;
    let hasAway = false;
    for (const segment of segments) {
        if (segment?.workLocationType === 'Office') {
            return 'Office';
        } else if (segment?.workLocationType === 'Remote') {
            hasRemote = true;
        } else if (segment?.workLocationType === 'TimeOff') {
            /*only show OOF in occurring work plan if flight is enabled*/
            hasAway = true;
        }
    }

    if (hasRemote) {
        return 'Remote';
    } else if (hasAway) {
        return 'TimeOff';
    }

    return 'Unspecified';
}
