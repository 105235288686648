import { getUserSessionSettings } from './getUserSessionSettings';

import type { ExternalDirectoryUserGuid } from './ExternalDirectoryUserGuid';

/**
 * Returns the ExternalDirectoryUserGuid (OID) of the current user.
 *
 * This function assumes user configuration settings to have been loaded already.
 * This is the case for normal application code, which runs after bootstrap.
 */
export function getUserExternalDirectoryUserGuid() {
    return (getUserSessionSettings().ExternalDirectoryUserGuid || '') as ExternalDirectoryUserGuid;
}
