import { initCalendarMailboxConfigurationForAllAccounts } from './actions/initCalendarMailboxConfiguration';
import { initTxpSettingsForAllAccounts } from './actions/initTxpSettings';
import { lazyGovern, GovernPriority } from 'owa-tti';
import { isBookWithMeModule, isAnonymousEventifyModule } from './utils/executionConditions';

// Load initial state needed for options
lazyGovern.importAndExecute(
    {
        task: () => initCalendarMailboxConfigurationForAllAccounts(),
        // Add conditions in case, this function is not desired to be run automatically when importing
        // these conditions will stop the execution of this function, in any other case this function
        // will be executed, keeping the current behavior
        condition: !isBookWithMeModule() && !isAnonymousEventifyModule(),
        name: 'initCalendarMailboxConfigurationForAllAccounts',
        priority: GovernPriority.Idle,
    },
    {
        task: () => initTxpSettingsForAllAccounts(),
        priority: GovernPriority.Idle,
        name: 'initTxpSettingsForAllAccounts',
    }
);
export { default as saveCalendarMailboxConfiguration } from './actions/saveCalendarMailboxConfiguration';

export { getCalendarConfiguration } from './selectors/getCalendarConfiguration';
export { getCalendarConfigurationState } from './selectors/getCalendarConfigurationState';
export { getTxpSettingsStatusCode } from './selectors/getTxpSettingsStatusCode';
export { getCalendarConfigWorkingHoursTimeZoneId } from './selectors/getCalendarConfigWorkingHoursTimeZoneId';
export { getTxpSettings } from './selectors/getTxpSettings';

export {
    default as initCalendarMailboxConfiguration,
    initCalendarMailboxConfigurationForAllAccounts,
} from './actions/initCalendarMailboxConfiguration';

export {
    default as initTxpSettings,
    initTxpSettingsForAllAccounts,
} from './actions/initTxpSettings';

export { default as reloadTxpSettings } from './actions/reloadTxpSettings';
export { default as updateCalendarMailboxConfiguration } from './actions/updateCalendarMailboxConfiguration';
export { default as updateTxpCalendarConfiguration } from './actions/updateTxpCalendarConfiguration';
export { default as setMailboxCalendarConfiguration } from './services/setMailboxCalendarConfiguration';

export type { default as TxpGriffinSettingsPayload } from './store/schema/TxpGriffinSettingsPayload';
export { TxpDisableReason } from './store/schema/TxpDisableReason';
