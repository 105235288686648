import { mutatorAction } from 'satcheljs';
import type MailboxCalendarConfiguration from 'owa-service/lib/contract/MailboxCalendarConfiguration';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getCalendarConfigurationState } from '../selectors/getCalendarConfigurationState';

export default mutatorAction(
    'updateCalendarMailboxConfiguration',
    function updateCalendarMailboxConfiguration(
        calendarConfiguration: MailboxCalendarConfiguration,
        mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
    ) {
        const currentConfiguration = getCalendarConfigurationState(mailboxInfo);
        if (currentConfiguration.calendarConfiguration) {
            // When receiving a partial calendarConfig, assign will add/update
            // the attributes present on the passed parameter into the source
            Object.assign(currentConfiguration.calendarConfiguration, calendarConfiguration);
        } else {
            currentConfiguration.calendarConfiguration = calendarConfiguration;
        }
    }
);
