import { getModuleState, setModuleState } from './Store';

import type { ModuleState } from './Store';

function setModuleStateSafe(newState: ModuleState) {
    if (getModuleState() !== newState) {
        setModuleState(newState);
    }
}

/**
 * Critical state is when the app is in boot or postBoot state.
 * This is used to determine if we should show loading screen or not.
 * This allows us to perform data fetching in the background without showing too much shimmering in the module.
 *
 * For devs: When to use this? if you think the UX would be better at initial App loading screen to be shown instead of waiting on shimmers or loading icon.
 */
function isAppInCiriticalState() {
    return getModuleState() === 'boot' || getModuleState() === 'postBoot';
}

/**
 * MetaOSAppCache state is when the app is in app caching of a MetaOS Host.
 * This is used to determine if we should unload expensive memory usage to keep cached state as minimal as possible.
 * check PlacesMetaOSAppCaching component which includes the setting of this state (and resetting to Complete state).
 * @returns true if the app is in metaOsAppCache state
 */
function isAppInMetaOSCachedState() {
    return getModuleState() === 'metaOsAppCache';
}

export { setModuleStateSafe, isAppInCiriticalState, isAppInMetaOSCachedState };
