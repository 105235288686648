import { isBefore, owaDate, today } from 'owa-datetime';

import type {
    FlexibleWorkingHoursView,
    GetScheduleFlexibleWorkingHoursView,
    HybridspaceGetScheduleFlexibleWorkingHoursView,
} from 'owa-graph-schema';

type InternalConfirmationFWH =
    | Partial<FlexibleWorkingHoursView>
    | Partial<GetScheduleFlexibleWorkingHoursView>
    | Partial<HybridspaceGetScheduleFlexibleWorkingHoursView>;

// the structure of the parameter is an array that has the FWH info of the user sorted into an array of length 7,
// each index representing a day of the week [Su, Mon, Tu, Wed, Thu, Fri, Sa]
// and if a particular index has data, that day contains FWH data for the user
export default function getIsPlanConfirmed(flexibleWorkingHoursData: InternalConfirmationFWH[][]) {
    const TODAY = today();
    // first we want to filter out lists that are empty or lists that contain data that is in the past
    const filteredList = flexibleWorkingHoursData.filter(
        (flexibleWorkingHoursInnerData: InternalConfirmationFWH[]) =>
            flexibleWorkingHoursInnerData.length > 0 &&
            flexibleWorkingHoursInnerData.every(
                (flexibleWorkingHours: InternalConfirmationFWH) =>
                    !isBefore(
                        owaDate(
                            flexibleWorkingHours.start?.timeZone.name,
                            flexibleWorkingHours.start?.dateTime
                        ),
                        TODAY
                    )
            )
    );

    // from this filtered list, we want to check that all segments are confirmed (only checking from today through the rest of the week)
    if (filteredList.length > 0) {
        return filteredList.every((flexibleWorkingHoursInnerData: InternalConfirmationFWH[]) =>
            flexibleWorkingHoursInnerData.every(
                (flexibleWorkingHours: InternalConfirmationFWH) =>
                    !!flexibleWorkingHours.isConfirmed
            )
        );
    }

    return false;
}

// the structure of the parameter is an array that contains all of the FWH segments for a collaborator
export function getIsPlanConfirmedForCollaborators(
    flexibleWorkingHoursData: InternalConfirmationFWH[]
) {
    const TODAY = today();
    // first we want to filter out lists that are empty or lists that contain data that is in the past
    if (flexibleWorkingHoursData.length > 0) {
        const filteredList = flexibleWorkingHoursData.filter(
            (flexibleWorkingHours: InternalConfirmationFWH) =>
                !isBefore(
                    owaDate(
                        flexibleWorkingHours.start?.timeZone.name,
                        flexibleWorkingHours.start?.dateTime
                    ),
                    TODAY
                )
        );

        // from this filtered list, we want to check that all segments are confirmed (only checking from today through the rest of the week)
        if (filteredList.length > 0) {
            return filteredList.every(
                (flexibleWorkingHours: InternalConfirmationFWH) =>
                    !!flexibleWorkingHours.isConfirmed
            );
        }
    }

    return false;
}
