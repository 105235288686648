import React from 'react';

export enum UserRoles {
    Admin = 'Admin',
    Employee = 'Employee',
}

export interface RoleContextType {
    userRole: UserRoles;
    setUserRole: (role: UserRoles) => void;
}

export const RoleContext = React.createContext<RoleContextType>({
    userRole: UserRoles.Admin,
    setUserRole: () => {},
});

export const useRoleContext = () => {
    return React.useContext(RoleContext);
};

export default function RoleContextProvider({ children }: { children: React.ReactNode }) {
    const [userRole, setUserRole] = React.useState(UserRoles.Admin);

    const contextValue = React.useMemo(
        () => ({
            userRole,
            setUserRole,
        }),
        [userRole, setUserRole]
    );

    return <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>;
}
