import { getUserExternalDirectoryId } from './getUserExternalDirectoryId';
import type { ExternalDirectoryIdWithPrefix } from './ExternalDirectoryIdWithPrefix';

/**
 * Returns the a string with the `OID:oid@tid` of the current user.
 *
 * This function assumes user configuration settings to have been loaded already.
 * This is the case for normal application code, which runs after bootstrap.
 */
export function getUserExternalDirectoryIdWithPrefix() {
    return ('OID:' + getUserExternalDirectoryId()) as ExternalDirectoryIdWithPrefix;
}
