// Build an arrays of array with a max size of chunkSize
// [a, b, c, d, e] chunkSize = 3
// Result: [[a, b, c], [d, e]]

/** MsGraph Users API has 15 as max */
const DefaultChunkSize = 14;
export function buildChunks<T>(arr: T[], chunkSize: number = DefaultChunkSize) {
    const chunks = [];
    let chunk = [];
    for (let i = 0; i < arr.length; i++) {
        chunk.push(arr[i] as T);
        if (chunk.length === chunkSize) {
            chunks.push(chunk);
            chunk = [];
        }
    }
    if (chunk.length > 0) {
        chunks.push(chunk);
    }
    return chunks;
}
