import { getDay, userDate } from 'owa-datetime';
import { setRecurringFWH } from 'places-user-session-stores';
import { getRecurringFilteredFlexibleWorkingHours } from './getFilteredFlexibleWorkingHours';
import { getWorkLocationForDay } from './getWorkLocationForDay';

import type { OwaDate } from 'owa-datetime';
import type {
    FlexibleWorkingHours,
    ListFlexibleWorkingHoursResponse,
    WorkLocationType,
    Location,
} from 'owa-graph-schema';
/**
 * This function is going to take the FWH for the user's recurring work plan
 * and set the key as the DayOfWeek and the value as the work location for that day taking all segments for that day into account
 * @param flexibleWorkingHours
 */

export function setRecurringFWHInStore(
    flexibleWorkingHours: ListFlexibleWorkingHoursResponse | undefined | null
) {
    if (flexibleWorkingHours) {
        const recurringFWH = new Array<{
            date: OwaDate;
            workLocation: WorkLocationType;
            workLocationDetails?: Location[];
        }>();

        const recurringFWHSegments = getRecurringFilteredFlexibleWorkingHours(
            flexibleWorkingHours?.segments
        );

        recurringFWHSegments?.forEach((fwhData: FlexibleWorkingHours[]) => {
            if (fwhData.length > 0) {
                const date = userDate(fwhData[0].start.dateTime);
                const workLocation = getWorkLocationForDay(fwhData);
                const workLocationDetails: Location[] = [];
                fwhData.every((fwhDaySegment: FlexibleWorkingHours) => {
                    if (fwhDaySegment.workLocationDetails) {
                        workLocationDetails.push(fwhDaySegment.workLocationDetails);
                    }
                });
                recurringFWH.push({ date, workLocation, workLocationDetails });
            }
        });

        // depending on when the recurrences were set, the dates do not need to be chronologically ascending
        // thus, we look at the Day instead of the Date
        const sortedRecurringFWH = [...recurringFWH].sort(
            (elementA, elementB) => getDay(elementA.date) - getDay(elementB.date)
        );

        setRecurringFWH(sortedRecurringFWH);
    }
}
