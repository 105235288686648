import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type {
    CalendarConfigurationStateMap,
    CalendarConfigurationState,
} from './schema/CalendarConfigurationState';

export function defaultCalendarConfigurationState(): CalendarConfigurationState {
    return {
        calendarConfiguration: null,
        txpSettings: null,
        statusCode: 0,
    };
}

const calendarConfigurationStore = createStore<CalendarConfigurationStateMap>(
    'calendarConfiguration',
    {
        entries: new ObservableMap(),
    }
)();

export default calendarConfigurationStore;
