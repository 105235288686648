import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Gets the base url to call TXP service during side to side migration from Griffin to micro repo.
 * Temporary function to switch to the new repo. If the flight to use migrated repo is enabled it replaces the service base url.
 */
export default function getTxpServiceBaseUrl(baseUrl: string): string {
    return isFeatureEnabled('cal-txp-useTxpNetCore')
        ? baseUrl.replace('txpb2/', 'txpB2NetCore/')
        : baseUrl;
}
