export const GRIFFIN_REQUEST_BASEURL = 'txpb2/api/v1.1/me/{0}';
export const GRIFFIN_REQUEST_UNBLOCK = 'txpb2/api/v1.0/me/{0}';
export const USER_SETTINGS = 'UserSettings';
export const UNBLOCK = 'Unblock';

// Datapoint names

export const TxpSettingsDataPointNames = {
    // name for datapoint which logs TXP loading settings.
    TXPSettingsLoad: 'TxpSettingsLoad',

    //name for datapoint which logs Txp setting response is error.
    TXPSettingsResponseError: 'TXPSettingsResponseError',

    // name for datapoint which logs TXP settings save V2
    TxpSettingsSaveV2: 'TxpSettingsSaveV2',

    // name for datapoint which logs when the user has turned off their autoblocked status
    TxpUnblock: 'TxpUnblock',
};
