import createMeasurementUnit from 'owa-measurementunit-formatter/lib/createMeasurementUnit';
import getSingularPluralExpressions from 'owa-measurementunit-formatter/lib/getSingularPluralExpressions';
import type { DurationMagnitudes, DurationSingularPluralStrings } from './DurationTypes';

export type { DurationSingularPluralStrings };

/** Creates the set of duration units, with the given set of magnitudes
 *  the strings that represent the singular and plural forms of each unit.
 */
export default function createDurationUnits(
    userCulture: string,
    magnitudes: DurationMagnitudes,
    strings: DurationSingularPluralStrings,
    useSimplePluralForm?: boolean
) {
    const expressions = getSingularPluralExpressions(userCulture);

    return {
        year: createMeasurementUnit(magnitudes.year, strings.year, expressions),
        month: createMeasurementUnit(magnitudes.month, strings.month, expressions),
        week: createMeasurementUnit(magnitudes.week, strings.week, expressions),
        day: createMeasurementUnit(magnitudes.day, strings.day, expressions),
        hour: createMeasurementUnit(
            magnitudes.hour,
            strings.hour,
            expressions,
            useSimplePluralForm
        ),
        minute: createMeasurementUnit(
            magnitudes.minute,
            strings.minute,
            expressions,
            useSimplePluralForm
        ),
        second: createMeasurementUnit(
            magnitudes.second,
            strings.second,
            expressions,
            useSimplePluralForm
        ),
    };
}
