import { getDay, userDate } from 'owa-datetime';

import { getOccurringFilteredFlexibleWorkingHours as getOccurringFilteredFWH } from 'hybridspace-graphql/lib/utils/getOccurringFilteredFlexibleWorkingHours';

import type { FlexibleWorkingHoursView, FlexibleWorkingHours } from 'owa-graph-schema';
export function getRecurringFilteredFlexibleWorkingHours(
    flexibleWorkingHours: FlexibleWorkingHours[] | undefined | null
): FlexibleWorkingHours[][] {
    // given the flexible working hours view, I need to compartmentalize each day into a map

    // this 2D array will store flexible working hours segments corresponding to the work day
    // the DayOfWeek will be the index (as each DayOfWeek corresponds to a numerical value 0-6 for Sunday through Saturday)
    // and each array within the array will contain an array of FWH segments corresponding to that DayOfWeek
    //                                                              Su   M  Tu   W  Th   F  Sa
    const filteredFlexibleWorkingHours: FlexibleWorkingHours[][] = [[], [], [], [], [], [], []];

    if (flexibleWorkingHours && flexibleWorkingHours.length > 0) {
        for (let i = 0; i < flexibleWorkingHours.length; i++) {
            const currentWorkDay = getDay(userDate(flexibleWorkingHours[i].start.dateTime));
            filteredFlexibleWorkingHours[currentWorkDay].push(flexibleWorkingHours[i]);
        }
    }
    return filteredFlexibleWorkingHours;
}

export function getOccurringFilteredFlexibleWorkingHours(
    flexibleWorkingHours: FlexibleWorkingHoursView[] | undefined | null
): FlexibleWorkingHoursView[][] {
    // given the flexible working hours view, I need to compartmentalize each day into a map

    // this 2D array will store flexible working hours segments corresponding to the work day
    // the DayOfWeek will be the index (as each DayOfWeek corresponds to a numerical value 0-6 for Sunday through Saturday)
    // and each array within the array will contain an array of FWH segments corresponding to that DayOfWeek
    //                                                                  Su   M  Tu   W  Th   F  Sa
    return getOccurringFilteredFWH(flexibleWorkingHours);
}
