/** Creates an object that represent the different forms of a unit's suffix */
export default function createSingularPluralStrings(
    singular: string,
    firstPlural: string,
    secondPlural: string,
    simplifiedPlural?: string
) {
    return {
        singular,
        firstPlural,
        secondPlural,
        simplifiedPlural,
    };
}
