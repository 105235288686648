import {
    ScreenWidthMinSmall,
    ScreenWidthMinMedium,
    ScreenWidthMinLarge,
    ScreenWidthMinXLarge,
    ScreenWidthMinXXLarge,
    ScreenWidthMinXXXLarge,
} from '@fluentui/react';

/**
 * @deprecated Use useWindowBreakpoints if checking the current breakpoint status.
 * If you need the value, use FLUENT_SMALL_SCREEN_WIDTH_IN_PIXELS instead.
 */
export const XTRA_SMALL_SCREEN_WIDTH_IN_PIXELS = 320;
/**
 * @deprecated Use useWindowBreakpoints if checking the current breakpoint status.
 * If you need the value, use FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS instead.
 */
export const SMALL_SCREEN_WIDTH_IN_PIXELS = 480;
/**
 * @deprecated Use useWindowBreakpoints if checking the current breakpoint status.
 * If you need the value, use FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS instead.
 */
export const MEDIUM_SCREEN_WIDTH_IN_PIXELS = 640;
/**
 * @deprecated Use useWindowBreakpoints if checking the current breakpoint status.
 * If you need the value, use FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS instead.
 */
export const LARGE_SCREEN_WIDTH_IN_PIXELS = 1024;

// Fluent UI Breakpoints
/**
 * Use useWindowBreakpoints if checking the current breakpoint status.
 */
export const FLUENT_SMALL_SCREEN_WIDTH_IN_PIXELS = ScreenWidthMinSmall;
/**
 * Use useWindowBreakpoints if checking the current breakpoint status.
 */
export const FLUENT_MEDIUM_SCREEN_WIDTH_IN_PIXELS = ScreenWidthMinMedium;
/**
 * Use useWindowBreakpoints if checking the current breakpoint status.
 */
export const FLUENT_LARGE_SCREEN_WIDTH_IN_PIXELS = ScreenWidthMinLarge;
/**
 * Use useWindowBreakpoints if checking the current breakpoint status.
 */
export const FLUENT_XLARGE_SCREEN_WIDTH_IN_PIXELS = ScreenWidthMinXLarge;
/**
 * Use useWindowBreakpoints if checking the current breakpoint status.
 */
export const FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS = ScreenWidthMinXXLarge;
/**
 * Use useWindowBreakpoints if checking the current breakpoint status.
 */
export const FLUENT_XXXLARGE_SCREEN_WIDTH_IN_PIXELS = ScreenWidthMinXXXLarge;

// Temporal constants
export const IPAD_SCREEN_WIDTH_IN_PIXELS = 768;
export const WORK_PLAN_MED_WIDTH_IN_PIXELS = 817;
/**
 * @deprecated Use useWindowBreakpoints if checking the current breakpoint status.
 * If you need the value, use FLUENT_XXLARGE_SCREEN_WIDTH_IN_PIXELS instead.
 */
export const XTRA_LARGE_SCREEN_WIDTH_IN_PIXELS = 1366;
