import type {
    FlexibleWorkingHoursInput,
    WorkLocationType,
    EnhancedLocation,
    ResolverContext,
} from 'owa-graph-schema';
import { getUserEmailAddress } from 'accelerator-aad/lib/getUserEmailAddress';
import { UpdateFlexibleWorkingHoursDocument } from 'owa-calendar-working-hours-option/lib/graphql/__generated__/updateFlexibleWorkingHoursMutation.interface';
import getWorkLocationDetails from './getWorkLocationDetails';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function updateOccurringFlexibleWorkingHours(
    mailboxInfo: MailboxInfo,
    workHoursDef: FlexibleWorkingHoursInput,
    workLocationType?: WorkLocationType
) {
    const newWorkLocationType = workLocationType ?? workHoursDef.workLocationType;
    const isOffice: boolean = newWorkLocationType === 'Office';

    const workLocationDetails = getWorkLocationDetails(isOffice, {
        Id: workHoursDef?.workLocationDetails?.id,
        DisplayName: workHoursDef?.workLocationDetails?.displayName,
    } as EnhancedLocation);

    // occurrenceDetails property needs to be undefined for OOF segment creation scenario
    const occurrenceDetails = workHoursDef.occurrenceDetails
        ? {
              seriesId: workHoursDef.occurrenceDetails.seriesId,
              seriesMasterId: workHoursDef.occurrenceDetails.seriesMasterId,
          }
        : undefined;

    const isMultiAccountEnabled = isFeatureEnabled('cal-surface-places-multi-account', mailboxInfo);

    const context = isMultiAccountEnabled
        ? ({
              requestOptions: {
                  mailboxInfo,
              },
          } as ResolverContext)
        : undefined;

    return {
        mutation: UpdateFlexibleWorkingHoursDocument,
        variables: {
            input: {
                mailboxId: isMultiAccountEnabled
                    ? mailboxInfo.mailboxSmtpAddress
                    : getUserEmailAddress(),
                updateDetails: {
                    segment: {
                        id: workHoursDef.id,
                        start: {
                            dateTime: workHoursDef.start.dateTime,
                            timeZone: {
                                name: workHoursDef.start.timeZone?.name ?? '',
                            },
                        },
                        end: {
                            dateTime: workHoursDef.end.dateTime,
                            timeZone: {
                                name: workHoursDef.end.timeZone?.name ?? '',
                            },
                        },
                        occurrenceDetails,
                        workLocationType: newWorkLocationType,
                        workLocationDetails,
                        subject: workHoursDef.subject,
                        isAllDay: workHoursDef.isAllDay,
                    },
                },
            },
        },
        context,
    };
}
