import { getISOStringWithOffset } from 'owa-datetime';

import type { OwaDate } from 'owa-datetime';

export function getGqlDateTime(date: OwaDate) {
    return {
        dateTime: getISOStringWithOffset(date),
        timeZone: { name: date.tz },
    };
}
