import { mutatorAction } from 'satcheljs';
import getMailboxCalendarConfiguration from '../services/getMailboxCalendarConfiguration';
import type MailboxCalendarConfiguration from 'owa-service/lib/contract/MailboxCalendarConfiguration';
import type { MailboxInfo } from 'owa-client-types';
import { getCalendarConfigurationState } from '../selectors/getCalendarConfigurationState';
import {
    getCalendarAccountSources,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';

export default async function initCalendarMailboxConfigurationForAccount(
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
) {
    if (getCalendarConfigurationState(mailboxInfo).calendarConfiguration) {
        //If we already have the mailbox configuration, we won't get it again"
        return;
    }

    initCalendarConfigurationForAccount(
        mailboxInfo,
        await getMailboxCalendarConfiguration(mailboxInfo)
    );
}

const initCalendarConfigurationForAccount = mutatorAction(
    'setCalendarConfiguration',
    (mailboxInfo: MailboxInfo, config: MailboxCalendarConfiguration) => {
        // Object.assign cannot be used here since the object is null
        getCalendarConfigurationState(mailboxInfo).calendarConfiguration = config;
    }
);

export async function initCalendarMailboxConfigurationForAllAccounts() {
    const initPromises = getCalendarAccountSources().map(accountSource =>
        initCalendarMailboxConfigurationForAccount(accountSource.mailboxInfo)
    );
    await Promise.all(initPromises);
}
