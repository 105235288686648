import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type MailboxCalendarConfiguration from 'owa-service/lib/contract/MailboxCalendarConfiguration';
import type OptionsResponseBase from 'owa-service/lib/contract/OptionsResponseBase';
import { default as setMailboxCalendarConfiguration } from '../services/setMailboxCalendarConfiguration';
import updateCalendarMailboxConfiguration from './updateCalendarMailboxConfiguration';
import type { SaveCalendarMailboxEntrypoint } from '../store/schema/SaveCalendarMailboxEntryPoint';

/**
 * This function saves the mailbox calendar configuration to the server
 * and also updates the in-memory cache of this configuration.
 *
 * @param mailboxCalendarConfiguration Configuration object to save
 * @param entryPoint Name of the entry point in code, for logging purposes
 * @param mailboxInfo Account where to apply setting to
 */
export default async function saveCalendarMailboxConfiguration(
    mailboxCalendarConfiguration: MailboxCalendarConfiguration,
    entryPoint: SaveCalendarMailboxEntrypoint,
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): Promise<OptionsResponseBase> {
    updateCalendarMailboxConfiguration(mailboxCalendarConfiguration, mailboxInfo);
    const response: OptionsResponseBase = await setMailboxCalendarConfiguration(
        mailboxCalendarConfiguration,
        entryPoint,
        mailboxInfo
    );
    return response;
}
