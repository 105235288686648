import { Icon } from '@fluentui/react';
import Hybrid from '../assets/Hybrid.svg';
import Desktop from '../assets/Desktop.svg';
import Office from '../assets/Office.svg';
import LocationDismissRegular from 'owa-fluent-icons-svg/lib/icons/LocationDismissRegular';
import React from 'react';
import { icon, noLocationIconStyles } from './WorkLocationButtonIcons.scss';

export const OfficeIcon = <img src={Office} className={icon} />;
export const HybridIcon = <img src={Hybrid} className={icon} />;
export const RemoteIcon = <img src={Desktop} className={icon} />;
export const NoWorkLocationIcon = (
    <Icon className={noLocationIconStyles} iconName={LocationDismissRegular} />
);
