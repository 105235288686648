import mobile from 'is-mobile';
import { IPAD_SCREEN_WIDTH_IN_PIXELS } from '../constants';

/**
 * Check if the mobile screen size permits all Places features to be enabled
 * @param width the width of the screen
 * @returns true if the mobile screen size permits all Places features to be enabled, false otherwise
 */
export function isMobileScreenSizeEnabled(width: number) {
    const isDeviceMobile = mobile();
    // As per request for temporal fix, every mobile screen less than iPad mini screen size will be disabled
    const isScreenSizeEnabled = width > IPAD_SCREEN_WIDTH_IN_PIXELS;
    // disable the screen is the screensize is less than iPad mini and is a mobile device
    return !isDeviceMobile || (isDeviceMobile && isScreenSizeEnabled);
}
