import type { ScheduleInformation, HybridspaceScheduleInformation } from 'owa-graph-schema';

export function getRanking(schedule?: ScheduleInformation | HybridspaceScheduleInformation) {
    let rank = 0;

    // Add points if has fwh set up
    (schedule?.flexibleWorkingHours?.length || 0) > 0 && rank++;

    return rank;
}
