import { type WorkLocationType } from 'owa-graph-schema';
import {
    placesWorkLocationInOffice,
    placesWorkLocationRemote,
    placesWorkLocationAway,
    placesWorkLocationNone,
} from '../strings/workPlaceLabels.locstring.json';
import {
    WORKLOCATIONTYPE_OFFICE_STRING,
    WORKLOCATIONTYPE_REMOTE_STRING,
    WORKLOCATIONTYPE_TIMEOFF_STRING,
} from '../constants/workWeekLabels';

export function getWorkLocationLabelResourceId(workLocation: WorkLocationType) {
    switch (workLocation) {
        case WORKLOCATIONTYPE_OFFICE_STRING:
            return placesWorkLocationInOffice;
        case WORKLOCATIONTYPE_REMOTE_STRING:
            return placesWorkLocationRemote;
        case WORKLOCATIONTYPE_TIMEOFF_STRING:
            return placesWorkLocationAway;
        default:
            return placesWorkLocationNone;
    }
}
