import { getCurrentCulture } from 'owa-localize';
import type { DurationProperties } from '../DurationTypes';
import type { FormattingOptions } from './createAbbreviatedDurationFormatter';
import createAbbreviatedDurationFormatter from './createAbbreviatedDurationFormatter';

export type { FormattingOptions };

/** Creates a duration formatter using the user's culture and the standard abbreviated strings. */
export function createAbbreviatedFormatter(
    options?: FormattingOptions,
    durationUnitsProps?: DurationProperties[],
    useSimplePluralForm?: boolean
) {
    return createAbbreviatedDurationFormatter(
        getCurrentCulture(),
        options,
        durationUnitsProps,
        useSimplePluralForm
    );
}

/**
 * Format a duration value using the user's culture and the standard abbreviated strings.
 * When formatting multiple values, it is better to use createFormatter and then
 * invoke it multiple times.
 */
export default function abbreviatedFormatDuration(
    value: number,
    options?: FormattingOptions,
    durationUnitsProps?: DurationProperties[]
) {
    const formatter = createAbbreviatedFormatter(options, durationUnitsProps);
    return formatter(value);
}
