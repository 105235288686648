import { useManagedQuery } from 'owa-apollo-hooks';
import { GetListUserFeatureAccessDocument } from '../graphql/__generated__/getListUserFeatureAccessQuery.interface';

export default function useListUserFeatureAccess(skip = false) {
    const { data, loading, error } = useManagedQuery(GetListUserFeatureAccessDocument, {
        skip,
        // Debatching listUserFeatureAccess query because this is having a perf issue (20230418 4s@P90, 6.4s@P95, 45s@P99)
        // noBatching will use HttpLink instead of BatchHttpLink in ApolloLink, to avoid batching with other queries.
        context: { noBatching: true },
    });
    return {
        featureAccess: data?.listUserFeatureAccess?.featureAccess || [],
        loading,
        error,
    };
}
