import type NotificationBarHostId from 'owa-notification-bar/lib/store/schema/NotificationBarHostId';
import { useEffect } from 'react';
import type { ResourceId } from 'owa-localize';
import { showNotification } from './showNotification';

/**
 * Show a notification bar with an error message and optional action button
 * during a react effect, if an error object is present, for example, an ApolloError.
 *
 * @param hostId ID of the NotificationBar that will show the notification.
 * Expected usage is for applications to create a bound function with the hostId
 * and then call that function to show the notification, so we don't have to duplicate
 * the hostId in every call.
 *
 * @param error Error value. The message is shown if this value is truthy.
 *
 * @param messageTextResourceId Resource ID of the message text. We expect the messages to be
 * short, well-known strings instead of dynamic strings. If needed, actionCallback can be used
 * to open a dialog with additional details.
 *
 * @param messageIconName Name of an icon to show next to the message.
 * @param actionTextResourceId Resource Id of the action button text.
 * @param actionCallback Callback called when the action button is clicked. Could be a retry button.
 */
export function useShowNotificationOnError(
    hostId: NotificationBarHostId,
    error: unknown | null | undefined,
    messageTextResourceId: ResourceId,
    messageIconName?: string,
    actionTextResourceId?: ResourceId,
    actionCallback?: () => void
) {
    useEffect(() => {
        if (!!error) {
            showNotification(
                hostId,
                messageTextResourceId,
                messageIconName,
                actionTextResourceId,
                actionCallback
            );
        }
    }, [
        error,
        hostId,
        messageTextResourceId,
        messageIconName,
        actionTextResourceId,
        actionCallback,
    ]);
}
