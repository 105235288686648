import type { MailboxInfo } from 'owa-client-types';
import {
    getCoprincipalMailboxInfoForMailboxInfo,
    getGlobalSettingsAccountMailboxInfo,
} from 'owa-account-source-list-store';

/**
 * Returns the Coprincipal MailboxInfo associated with the supplied MailboxInfo (if any)
 * or the MailboxInfo of the account where global settings are stored
 * @param mailboxInfo Identifies a mailbox source
 * @returns MailboxInfo of the associated Coprincipal account, or undefined
 */
export default function getCoprincipalOrGlobalSettingsMailboxInfo(
    mailboxInfo: MailboxInfo | null | undefined
): MailboxInfo {
    return (
        (mailboxInfo ? getCoprincipalMailboxInfoForMailboxInfo(mailboxInfo) : undefined) ||
        getGlobalSettingsAccountMailboxInfo()
    );
}
