import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';

/**
 * Module state indicates the critical rendering state machine of the app.
 * The idea of this value is to allow the apps to perform certain condition based on the state of the app.
 *
 * "boot" - The app is in initial state, when bootstrap begins.
 * "postBoot" - The app is in critical state, when bootstrap is complete, but we want to prepare data before we remove loading screen.
 * "complete" - The app is in complete state, when devs think the module is ready for users to look at and we can remove loading screen.
 * "criticalError" - The app is in critical error state, where it is not functional at all, show an app wide error page.
 * "metaOsAppCache" - The app is in meta os app cache state, features could use this state to unload expensive memory usages to keep cached state as minimal as possible.
 * ""
 */
type ModuleState = 'boot' | 'postBoot' | 'complete' | 'criticalError' | 'metaOsAppCache';

const StoreName = 'hybridspace-appview-store';
const getStore = createStore<{
    moduleState: null | ModuleState;
}>(StoreName, {
    moduleState: null,
});

const { getModuleState, setModuleState } = createAccessors(StoreName, getStore);

export { getModuleState, setModuleState, ModuleState };
