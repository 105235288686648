import { isSameWeek, today } from 'owa-datetime';
import { action, mutatorAction, orchestrator } from 'satcheljs';
import { DateRangeType } from '@fluentui/date-time-utilities';
import { getDefaultWeekRange } from '../utils/getDefaultWeekRange';
import { getUserWeekStartDay } from '../utils/getUserWeekStartDay';
import { getCurrentDateRange, getStore, setCurrentDate, setCurrentDateRange } from './Store';

import type { OwaDate } from 'owa-datetime';
import type { DateRange } from 'owa-datetime-utils';

const initializeWeekPickerViewState = mutatorAction('initializeWeekPickerViewState', () => {
    getStore().currentDate = today();
    getStore().currentDateRange = getDefaultWeekRange();
    getStore().currentDateRangeType = DateRangeType.WorkWeek;
});

function isTodayInCurrentDateRange() {
    return isSameWeek(getCurrentDateRange().start, today(), getUserWeekStartDay());
}

/**
 * Action to update current date
 */
const updateCurrentDate = action('updateCurrentDate', (date: OwaDate) => ({
    date,
}));

orchestrator(updateCurrentDate, ({ date }) => setCurrentDate(date));

/**
 * Action to update current date range
 */
const updateCurrentDateRange = action(
    'updateCurrentDateRange',
    (dateRange: DateRange, shouldUseCollaboratorsOperations: boolean = true) => ({
        dateRange,
        shouldUseCollaboratorsOperations,
    })
);

orchestrator(updateCurrentDateRange, ({ dateRange }) => setCurrentDateRange(dateRange));

export {
    initializeWeekPickerViewState,
    isTodayInCurrentDateRange,
    updateCurrentDateRange,
    updateCurrentDate,
};
