import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';

const ALL_BUILDINGS_OPTION = 'all-buildings';

const {
    setFilterTextValue,
    getFilterTextValue,
    setFilterOptions,
    getFilterOptions,
    getShowBuildingFilterOptions,
    setShowBuildingFilterOptions,
} = createAccessors(
    'hybridspace-location-view-filter',
    createStore('hybridspace-location-view-filter', {
        filterTextValue: '',
        filterOptions: [ALL_BUILDINGS_OPTION],
        showBuildingFilterOptions: undefined as boolean | undefined,
    })
);

export {
    setFilterTextValue,
    getFilterTextValue,
    setFilterOptions,
    getFilterOptions,
    ALL_BUILDINGS_OPTION,
    getShowBuildingFilterOptions,
    setShowBuildingFilterOptions,
};
