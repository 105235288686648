import setMailboxCalendarConfigurationOperation from 'owa-service/lib/operation/setMailboxCalendarConfigurationOperation';
import setMailboxCalendarConfigurationRequest from 'owa-service/lib/factory/setMailboxCalendarConfigurationRequest';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import type MailboxCalendarConfiguration from 'owa-service/lib/contract/MailboxCalendarConfiguration';
import type OptionsResponseBase from 'owa-service/lib/contract/OptionsResponseBase';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { type RequestQueue, createRequestQueue } from 'owa-service-utils';
import { PerformanceDatapoint } from 'owa-analytics';
import getDatapointStatus from 'owa-analytics/lib/utils/getDatapointStatus';
import type { SaveCalendarMailboxEntrypoint } from '../store/schema/SaveCalendarMailboxEntryPoint';

const MAXIMUM_NUM_REQUESTS = 6;

let requestQueue: RequestQueue | undefined = undefined;

export default function setMailboxCalendarConfiguration(
    options: MailboxCalendarConfiguration,
    entryPoint: SaveCalendarMailboxEntrypoint,
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): Promise<OptionsResponseBase> {
    const datapoint = new PerformanceDatapoint('SetMailboxCalendarConfiguration');

    datapoint.addCustomData({ entryPoint });

    if (!requestQueue) {
        requestQueue = createRequestQueue(MAXIMUM_NUM_REQUESTS);
    }

    const userConfiguration = getAccountScopeUserSettings(mailboxInfo);

    const requestOptions = getMailboxRequestOptions(mailboxInfo, { perfDatapoint: { datapoint } });

    // Add telemetry surrounding the enqueue of this request and time in queue if applicable
    const requestEnqueued = requestQueue?.getRunningCount() === MAXIMUM_NUM_REQUESTS;
    datapoint.addCustomData({ requestEnqueued });
    const enqueuedAt = new Date();

    // when OwaMailboxPolicy has LocalEventsEnabled set to false, we don't provide
    // LocalEventsEnabled and LocalEventsLocation properties in the request.
    // Otherwise the insufficient permissions error is returned.
    if (userConfiguration.PolicySettings?.LocalEventsEnabled) {
        return requestQueue.add(() => {
            const requestExecutionStart = new Date();
            return setMailboxCalendarConfigurationOperation(
                setMailboxCalendarConfigurationRequest({
                    Header: getJsonRequestHeader(),
                    Options: options,
                }),
                requestOptions
            ).then(
                response => {
                    stampSuccess(datapoint, requestEnqueued, enqueuedAt, requestExecutionStart);
                    return response;
                },
                error => {
                    stampErrorInformation(
                        error,
                        datapoint,
                        requestEnqueued,
                        enqueuedAt,
                        requestExecutionStart
                    );
                    throw error;
                }
            );
        });
    } else {
        const { LocalEventsEnabled, LocalEventsLocation, ...rest } = options;
        return requestQueue.add(() => {
            const requestExecutionStart = new Date();
            return setMailboxCalendarConfigurationOperation(
                setMailboxCalendarConfigurationRequest({
                    Header: getJsonRequestHeader(),
                    Options: rest,
                }),
                requestOptions
            ).then(
                response => {
                    stampSuccess(datapoint, requestEnqueued, enqueuedAt, requestExecutionStart);
                    return response;
                },
                error => {
                    stampErrorInformation(
                        error,
                        datapoint,
                        requestEnqueued,
                        enqueuedAt,
                        requestExecutionStart
                    );
                    throw error;
                }
            );
        });
    }
}

/**
 * Helper function that stamps into the datapoint's CustomData field the time a given request spent waiting
 * in the request queue.
 */
function stampQueueTime(
    datapoint: PerformanceDatapoint,
    requestEnqueued: boolean,
    enqueuedAt: Date,
    dequeuedAt: Date
) {
    if (requestEnqueued) {
        datapoint.addCustomData({
            timeInQueueMs: dequeuedAt.getTime() - enqueuedAt.getTime(),
        });
    }
}

/**
 * Helper that stamps error status and telemetry in case the request fails
 *
 * @param error Error thrown
 * @param datapoint PerformanceDatapoint tracking the request
 * @param requestEnqueued Flag that signals if the request had to wait on the request queue or was immediately executed
 * @param enqueuedAt Timestamp when the request entered the queue
 * @param dequeuedAt Timestamp when the request was taken out of the queue for execution
 */
function stampErrorInformation(
    error: Error,
    datapoint: PerformanceDatapoint,
    requestEnqueued: boolean,
    enqueuedAt: Date,
    dequeuedAt: Date
) {
    stampQueueTime(datapoint, requestEnqueued, enqueuedAt, dequeuedAt);
    const autoErrorStatusAndType = getDatapointStatus(error.message, error);
    datapoint.endWithError(autoErrorStatusAndType.status, error);
}

/**
 * Helper that stamps telemetry and ends the datapoint when the request succeeds
 *
 * @param datapoint PerformanceDatapoint tracking the request
 * @param requestEnqueued Flag that signals if the request had to wait on the request queue or was immediately executed
 * @param enqueuedAt Timestamp when the request entered the queue
 * @param dequeuedAt Timestamp when the request was taken out of the queue for execution
 */
function stampSuccess(
    datapoint: PerformanceDatapoint,
    requestEnqueued: boolean,
    enqueuedAt: Date,
    dequeuedAt: Date
) {
    stampQueueTime(datapoint, requestEnqueued, enqueuedAt, dequeuedAt);
    datapoint.end();
}
