export const yearSingularAbbreviation = "QeZblb";
export const yearFirstPluralAbbreviation = "XUYe5c";
export const yearSecondGenitivePluralAbbreviation = "ITp63b";
export const monthSingularAbbreviation = "bwCXYd";
export const monthFirstPluralAbbreviation = "MQMxh";
export const monthSecondGenitivePluralAbbreviation = "h6iDFb";
export const weekSingularAbbreviation = "JXL2re";
export const weekFirstPluralAbbreviation = "YAyY7";
export const weekSecondGenitivePluralAbbreviation = "nFEVwc";
export const daySingularAbbreviation = "DwqHX";
export const dayFirstPluralAbbreviation = "IgT_Pd";
export const daySecondGenitivePluralAbbreviation = "nlaLJb";
export const hourSingularAbbreviation = "n6rFod";
export const hourFirstPluralAbbreviation = "W13x6c";
export const hourSecondGenitivePluralAbbreviation = "lQxvgb";
export const hourSimplifiedPluralAbbreviation = "mJotf";
export const minuteSingularAbbreviation = "TY9IZ";
export const minuteFirstPluralAbbreviation = "mJuPid";
export const minuteSecondGenitivePluralAbbreviation = "P1lFkc";
export const minuteSimplifiedPluralAbbreviation = "ssgt0c";
export const secondSingularAbbreviation = "B98Co";
export const secondFirstPluralAbbreviation = "Gfnpdc";
export const secondSecondGenitivePluralAbbreviation = "Rc46yd";
export const secondSimplifiedPluralAbbreviation = "GTapJd";
export default {yearSingularAbbreviation,yearFirstPluralAbbreviation,yearSecondGenitivePluralAbbreviation,monthSingularAbbreviation,monthFirstPluralAbbreviation,monthSecondGenitivePluralAbbreviation,weekSingularAbbreviation,weekFirstPluralAbbreviation,weekSecondGenitivePluralAbbreviation,daySingularAbbreviation,dayFirstPluralAbbreviation,daySecondGenitivePluralAbbreviation,hourSingularAbbreviation,hourFirstPluralAbbreviation,hourSecondGenitivePluralAbbreviation,hourSimplifiedPluralAbbreviation,minuteSingularAbbreviation,minuteFirstPluralAbbreviation,minuteSecondGenitivePluralAbbreviation,minuteSimplifiedPluralAbbreviation,secondSingularAbbreviation,secondFirstPluralAbbreviation,secondSecondGenitivePluralAbbreviation,secondSimplifiedPluralAbbreviation};