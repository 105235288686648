import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { MailboxInfo } from 'owa-client-types';
import type { CalendarConfigurationState } from '../store/schema/CalendarConfigurationState';
import { default as store, defaultCalendarConfigurationState } from '../store/store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export function getCalendarConfigurationState(
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): CalendarConfigurationState {
    const mapKey = getIndexerValueForMailboxInfo(mailboxInfo);
    if (!store.entries.get(mapKey)) {
        store.entries.set(mapKey, defaultCalendarConfigurationState());
    }
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return store.entries.get(mapKey)!;
}
