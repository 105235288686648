import { getUserExternalDirectoryTenantGuid } from './getUserExternalDirectoryTenantGuid';
import { getUserExternalDirectoryUserGuid } from './getUserExternalDirectoryUserGuid';

import type { ExternalDirectoryId } from './ExternalDirectoryId';

/**
 * Returns the a string with the `oid@tid` of the current user
 *
 * NOTE: strings returned by this funtion do not have the `OID:` prefix.
 *       Use getExternalDirectoryIdWithPrefix for that.
 *
 * This function assumes user configuration settings to have been loaded already.
 * This is the case for normal application code, which runs after bootstrap.
 */
export function getUserExternalDirectoryId() {
    return `${getUserExternalDirectoryUserGuid()}@${getUserExternalDirectoryTenantGuid()}` as ExternalDirectoryId;
}
