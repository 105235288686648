import { format } from 'owa-localize';
import { makeGetRequest } from 'owa-ows-gateway';
import { getGuid } from 'owa-guid';
import { GRIFFIN_REQUEST_BASEURL, USER_SETTINGS } from '../helpers/TxpConstants';
import { createDefaultHeaders } from 'owa-service/lib/createDefaultHeader';
import type TxpSettingsEntryPoint from '../store/schema/TxpSettingsEntryPoint';
import type EntityType from 'txp-data/lib/schema/innerSchema/EntityType';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import getTxpServiceBaseUrl from '../utils/getTxpServiceBaseUrl';
import { logCoreUsage } from 'owa-analytics';

export default function getUserSettingsAction(
    shouldLogDatapoint?: boolean,
    entryPoint?: TxpSettingsEntryPoint,
    entityType?: EntityType,
    mailboxInfo?: MailboxInfo
): Promise<Response> {
    const requestMailboxInfo: MailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    if (shouldLogDatapoint) {
        logCoreUsage('TxpSettingsLoad', {
            entryPoint,
            entityType,
        });
    }

    return createDefaultHeaders(undefined, requestMailboxInfo).then(headers => {
        headers['cache-control'] = 'no-store';

        const baseUrl = getTxpServiceBaseUrl(GRIFFIN_REQUEST_BASEURL);

        return makeGetRequest(
            format(baseUrl, USER_SETTINGS),
            getGuid(),
            true /*returnFullResponse*/,
            headers,
            undefined /*throwServiceError*/,
            undefined /* includeCredentials */,
            'TxpSettings' /* actionName */,
            requestMailboxInfo
        );
    });
}
