import { isSameDay } from 'owa-datetime';
import { getTimeUntilSeconds, isEventHappeningNow } from 'owa-datetime-utils';
import { createAbbreviatedFormatter } from 'owa-duration-formatter/lib/abbreviated';
import loc from 'owa-localize';
import {
    meetingCountdownInXTime,
    meetingCountdownNow,
    meetingCountdownUpNext,
} from './getMeetingCountdown.locstring.json';

import type { OwaDate } from 'owa-datetime';

let abbreviatedFormatterTimeUntil: ((arg0: number) => any) | null = null;

/**
 * Gets string describing the time until an upcoming or current meeting starts
 *
 * @param start start date for a meeting
 * @param end end date for a meeting
 * @param now current time
 */
export function getMeetingCountdown(start: OwaDate, end: OwaDate, now: OwaDate): string {
    /**
     * Case 1 - Show "Now" when event is happening now
     */

    if (isEventHappeningNow(now, start, end)) {
        return loc(meetingCountdownNow);
    }

    /**
     * Case 2 - Show time left when event will start today, e.g.
     *      "in {0} hr {1} mins"
     *      "in {0} hrs"
     *      "in {0} mins"
     */

    if (isSameDay(start, now)) {
        if (abbreviatedFormatterTimeUntil == null) {
            abbreviatedFormatterTimeUntil = createAbbreviatedFormatter({
                maxUnits: 2,
            });
        }

        const roundedDiffInSeconds = getTimeUntilSeconds(start, now);
        return loc(meetingCountdownInXTime, abbreviatedFormatterTimeUntil(roundedDiffInSeconds));
    }

    /**
     * Case 3 - Show "Up next" when event will start on a future day
     */

    return loc(meetingCountdownUpNext);
}
