import { createIconComponent } from 'owa-fluent-icons-svg';
import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';

export function registerIcon(name: string, svg: React.FC<React.SVGAttributes<SVGElement>>) {
    registerIcons({
        icons: {
            [name]: React.createElement(
                createIconComponent({
                    IconComponent: svg,
                    displayName: name,
                })
            ),
        },
    });

    return name;
}
