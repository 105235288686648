import type { AccTabItem } from 'accelerator-common/lib/AccTabList';
import { useRoleContext, UserRoles } from '../context/RoleContext';
import { getApplicationSettings } from 'owa-application-settings';
import { isFeatureEnabled } from 'owa-feature-flags';
import BuildingFilled from 'owa-fluent-icons-svg/lib/icons/BuildingFilled';
import BuildingRegular from 'owa-fluent-icons-svg/lib/icons/BuildingRegular';
import DataPieFilled from 'owa-fluent-icons-svg/lib/icons/DataPieFilled';
import DataPieRegular from 'owa-fluent-icons-svg/lib/icons/DataPieRegular';
import MapRegular from 'owa-fluent-icons-svg/lib/icons/MapRegular';
import MapFilled from 'owa-fluent-icons-svg/lib/icons/MapFilled';
import React from 'react';
import { ADMIN_ROOT, SPACE_ANALYTICS, SPACE_MANAGEMENT } from '../constants/adminRoutes';
import Dashboard from '../assets/Dashboard';
import DashboardFilled from '../assets/DashboardFilled';
import strings from '../strings/useHybridspaceAdminLinks.locstring.json';

export default function useHybridspaceAdminLinks(): AccTabItem[] {
    const isSpaceManagementEnabled = isFeatureEnabled('msplaces-space-management-page');

    const isDeprecatedSpaceManagementEnabled =
        !isSpaceManagementEnabled && getApplicationSettings('MsPlaces').spaceManagementTabEnabled;

    const { userRole } = useRoleContext();
    const roleSwitcherDisabled = isFeatureEnabled('msplaces-disableRoleSwitcher');
    const shouldHideAdminLinks = !roleSwitcherDisabled && userRole !== UserRoles.Admin;

    return React.useMemo(
        () => [
            {
                id: 'AdminDashboard',
                key: `/${ADMIN_ROOT}`,
                icon: Dashboard,
                activeIcon: DashboardFilled,
                textResourceId: strings.hybridspaceAdminDashboardLink,
                ariaLabelResourceId: strings.hybridspaceAdminDashboardLink,
                hidden: true,
            },
            {
                id: 'SpaceManagement_Deprecated',
                key: `/${ADMIN_ROOT}/${SPACE_MANAGEMENT}`,
                icon: BuildingRegular,
                activeIcon: BuildingFilled,
                textResourceId: strings.hybridspaceManagementLink,
                ariaLabelResourceId: strings.hybridspaceManagementLink,
                hidden: !isDeprecatedSpaceManagementEnabled || shouldHideAdminLinks,
            },
            {
                id: 'SpaceAnalytics',
                key: `/${ADMIN_ROOT}/${SPACE_ANALYTICS}`,
                icon: DataPieRegular,
                activeIcon: DataPieFilled,
                textResourceId: strings.hybridspaceAnalyticsLink,
                ariaLabelResourceId: strings.hybridspaceAnalyticsLink,
                hidden: shouldHideAdminLinks,
            },
            {
                id: 'SpaceManagement',
                key: `/${ADMIN_ROOT}/${SPACE_MANAGEMENT}`,
                icon: MapRegular,
                activeIcon: MapFilled,
                textResourceId: strings.hybridspaceManagementLink,
                ariaLabelResourceId: strings.hybridspaceManagementLink,
                hidden: !isSpaceManagementEnabled || shouldHideAdminLinks,
            },
        ],
        [isSpaceManagementEnabled, isDeprecatedSpaceManagementEnabled, shouldHideAdminLinks]
    );
}
