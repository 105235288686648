import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type TxpGriffinSettingsPayload from '../store/schema/TxpGriffinSettingsPayload';
import { getCalendarConfigurationState } from './getCalendarConfigurationState';

export const getTxpSettings = (
    mailboxInfo: MailboxInfo = getGlobalSettingsAccountMailboxInfo()
): TxpGriffinSettingsPayload | null => {
    return getCalendarConfigurationState(mailboxInfo).txpSettings;
};
