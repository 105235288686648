import { getCalendarAccountSources } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { getTxpSettings } from '../selectors/getTxpSettings';
import reloadTxpSettings from './reloadTxpSettings';

export default function initTxpSettings(mailboxInfo: MailboxInfo) {
    if (getTxpSettings(mailboxInfo)) {
        // If we already have the txp settings, we won't get it again
        return;
    }

    return reloadTxpSettings(
        false /* shouldLogDatapoint */,
        undefined /* entryPoint */,
        mailboxInfo
    );
}

export async function initTxpSettingsForAllAccounts() {
    const initPromises = getCalendarAccountSources().map(accountSource =>
        initTxpSettings(accountSource.mailboxInfo)
    );
    await Promise.all(initPromises);
}
