import { registerIcon } from 'accelerator-common/lib/registerIcon';
import React from 'react';

export default registerIcon(
    'HS_ADMIN_DashboardFilled',
    (props: React.SVGAttributes<SVGElement>) => (
        <svg
            {...props}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.24606 12.0014C8.21256 12.0014 8.99606 12.7849 8.99606 13.7514V17.2514C8.99606 18.2179 8.21256 19.0014 7.24606 19.0014H1.75C0.783502 19.0014 0 18.2179 0 17.2514V13.7514C0 12.7849 0.783502 12.0014 1.75 12.0014H7.24606ZM18.25 12.0014C19.2165 12.0014 20 12.7849 20 13.7514V17.2514C20 18.2179 19.2165 19.0014 18.25 19.0014H12.7539C11.7874 19.0014 11.0039 18.2179 11.0039 17.2514V13.7514C11.0039 12.7849 11.7874 12.0014 12.7539 12.0014H18.25ZM18.25 0.996216C19.2165 0.996216 20 1.77972 20 2.74622V8.24965C20 9.21615 19.2165 9.99965 18.25 9.99965H1.75C0.783502 9.99965 0 9.21615 0 8.24965V2.74622C0 1.82804 0.70711 1.07502 1.60647 1.00202L1.75 0.996216H18.25Z"
                fill="url(#paint0_linear_188_30800)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_188_30800"
                    x1="10"
                    y1="0.996216"
                    x2="10"
                    y2="19.0014"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#60CDFF" />
                    <stop offset="1" stopColor="#0883D8" />
                </linearGradient>
            </defs>
        </svg>
    )
);
