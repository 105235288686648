import { today } from 'owa-datetime';
import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'satcheljs';
import { DateRangeType } from '@fluentui/date-time-utilities';
import { getDefaultWeekRange } from '../utils/getDefaultWeekRange';

const getStore = createStore('hybridspace-week-picker', {
    currentDate: today(),
    currentDateRange: getDefaultWeekRange(),
    currentDateRangeType: DateRangeType.Week,
});
const {
    setCurrentDate,
    getCurrentDate,
    setCurrentDateRange,
    getCurrentDateRange,
    setCurrentDateRangeType,
    getCurrentDateRangeType,
} = createAccessors('hybridspace-week-picker', getStore);

export {
    getStore,
    setCurrentDate,
    getCurrentDate,
    setCurrentDateRange,
    getCurrentDateRange,
    setCurrentDateRangeType,
    getCurrentDateRangeType,
};
