import { mutatorAction } from 'satcheljs';
import type { ObservableMap } from 'mobx';

type Getters<ID, T> = {
    [K in keyof T as `get${Capitalize<string & K>}`]: (id: ID) => T[K];
};

type Setters<ID, T> = {
    [K in keyof T as `set${Capitalize<string & K>}`]: (id: ID, newValue: T[K]) => void;
};

/**
 * This function returns getter and setter accessors for an ObservableMap.
 * The getters and setters are for a given field in the map's values.
 * Setters are implemented as mutator-actions.
 *
 * @param storeName
 * Name of the store. Used to construct the name of the mutator actions.
 *
 * @param getObservableMap
 * Function that returns an ObservableMap. This needs to be in a satchel store.
 *
 * @param getInitialValue
 * Function that returns an initial value for a given id in the ObservableMap
 *
 * @param setInitialValueOnGet
 * Optional boolean to control whether getters automatically fill in the ObservableMap
 *
 * @returns
 * Getters and setters for each field in the store.
 *
 * Example:
 * If a an observableMap's value interface is called 'ViewState' is defined like this:
 * interface ViewState {
 *    property1: string;
 *    property2: SomeOtherType;
 * }
 * and if we pass a ObservableMap that looks like:
 * viewStates: ObservableMap<string, ViewState>
 * where the key is a string (let's call it viewStateId)
 *
 * This function returns:
 * getProperty1: (viewStateId: string) => string;
 * setProperty1: (viewStateId: string, newValue: string) => void;
 * getProperty2: (viewStateId: string) => SomeOtherType;
 * setProperty2: (viewStateId: string, newValue: SomeOtherType) => void;
 *
 * The viewStateId is the key of the ObservableMap. It can be a branded string so that the compiler can enforce strong key types.
 * (Ex, ExternalDirectoryUserGuid but not ExternalDirectoryGroupGuid, even thou they are both strings, and both GUIDs.)
 */
export default function createAccessorsForMapStore<ID extends string, T extends {}>(
    storeName: string,
    getObservableMap: () => ObservableMap<ID, T>,
    getInitialValue: () => T,
    setInitialValueOnGet?: boolean
): Getters<ID, T> & Setters<ID, T> {
    return Object.keys(getInitialValue()).reduce(
        (gettersAndSetters, key) => {
            const capitalizedPropertyName = key[0].toUpperCase() + key.substr(1);
            gettersAndSetters['get' + capitalizedPropertyName] = (id: ID) =>
                getObservableMap().has(id)
                    ? getObservableMap().get(id)?.[key as keyof T]
                    : setInitialValueOnGet
                    ? (getObservableMap().set(id, getInitialValue()).get(id) as T)[key as keyof T]
                    : null;
            /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
             * Baseline. DO NOT COPY AND PASTE!
             *	> Function should only be invoked in module scope */
            gettersAndSetters['set' + capitalizedPropertyName] = mutatorAction(
                storeName + '_SET_' + key.toUpperCase(),
                (id: ID, newValue: any) => {
                    const obj =
                        getObservableMap().get(id) ||
                        (getObservableMap().set(id, getInitialValue()).get(id) as T);
                    obj[key as keyof T] = newValue;
                }
            );
            return gettersAndSetters;
        },
        {} as {
            [key: string]: (...args: any[]) => any;
        }
    ) as Getters<ID, T> & Setters<ID, T>;
}
