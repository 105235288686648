import {
    parseMergedWorkLocationType,
    WorkLocationSurfaceType,
} from 'owa-calendar-working-hours-location-utils';
import { getDateString } from 'owa-datetime';

import type { OwaDate } from 'owa-datetime';
import type {
    GetScheduleFlexibleWorkingHoursView,
    HybridspaceGetScheduleFlexibleWorkingHoursView,
} from 'owa-graph-schema';
export default function getDayLocation(
    date: OwaDate,
    flexibleWorkingHours: Array<
        GetScheduleFlexibleWorkingHoursView | HybridspaceGetScheduleFlexibleWorkingHoursView
    >
) {
    if (flexibleWorkingHours.length === 0) {
        return 'Undefined';
    }
    const dateString = getDateString(date);
    const filteredFlexibleSchedule = flexibleWorkingHours?.filter(
        (
            flexibleWorkingHoursSetting:
                | GetScheduleFlexibleWorkingHoursView
                | HybridspaceGetScheduleFlexibleWorkingHoursView
        ) => {
            // Mapping flexible working hours to the date string regardless of the timezone
            return flexibleWorkingHoursSetting.start?.dateTime?.startsWith(dateString);
        }
    );

    const mergedWorkLocationType = parseMergedWorkLocationType(filteredFlexibleSchedule);
    if (mergedWorkLocationType === WorkLocationSurfaceType.Hybrid) {
        return WorkLocationSurfaceType.Office;
    }

    return mergedWorkLocationType as string;
}
